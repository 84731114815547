<template>
  <div class="page-content-webhook">
    <div class="header">
      <div class="style-title-setting-global">
        {{
          $i18n.locale === 'fr' ? 'Gestion des Webhooks' : 'Webhook Management'
        }}
      </div>
      <v-progress-circular
        v-if="getWebhookLoading && !initLoading"
        class="float-right mt-1"
        indeterminate
        color="#5C2DD3"
      ></v-progress-circular>
      <v-btn
        class="btn-filter"
        dark
        color="#5C2DD3"
        @click.stop="handleClickBtnAddWebhook"
      >
        <v-icon class="sz-icon-plus mr-1">mdi-plus</v-icon>
        {{
          $i18n.locale === 'fr'
            ? 'Créer un nouveau webhook'
            : 'Create new webhook'
        }}
      </v-btn>
    </div>
    <div class="loader-content" v-if="initLoading">
      <v-progress-circular
        indeterminate
        class="ml-3"
        color="#5C2DD3"
      ></v-progress-circular>
    </div>

    <div class="webhook mt-3" v-else>
      <v-simple-table class="webhook-table" fixed-header>
        <thead>
          <tr class="header-tr">
            <th>
              {{ $i18n.locale === 'fr' ? 'Événements' : 'Events' }}
            </th>
            <th>
              {{
                $i18n.locale === 'fr'
                  ? 'URL du point de destination'
                  : 'Destination point URL'
              }}
            </th>
            <th>{{ $i18n.locale === 'fr' ? 'Créé' : 'Created' }}</th>
            <th>
              {{
                $i18n.locale === 'fr' ? 'Dernière tentative' : 'Last attempt'
              }}
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="sub-header-tr"
            v-for="webhook in getWebhooks"
            :key="'webhook-' + webhook.id"
          >
            <td>{{ webhook.action | typeFormat }}.{{ webhook.object }}</td>
            <td>{{ webhook.url }}</td>
            <td>
              {{ webhook.created_at | date('DD MMM , YYYY') }}
              {{ webhook.created_at | time('HH:mm') }}<br />
              {{ webhook.created_by.data.full_name }}
            </td>
            <td>
              {{ webhook.last_delivery_time | date('DD MMM , YYYY') }}
              {{ webhook.last_delivery_time | time('HH:mm') }}<br />
              <span
                v-if="webhook.last_http_status == 200"
                class="status-success"
              >
                {{ webhook.last_http_status }}
              </span>
              <span v-else class="status-error">
                {{ webhook.last_http_status }}
              </span>
            </td>
            <td>
              <v-btn
                class="icon-action"
                small
                outlined
                color="#5C2DD3"
                :title="$t('deleted')"
                @click="clickdeleteWebhook(webhook)"
              >
                <font-awesome-icon class="img-h-19" icon="trash-alt" />
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
    <!-- add webhook -->
    <v-dialog v-model="addWebhookModal" max-width="600" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale === 'fr'
                ? 'Créer un nouveau webhook'
                : 'Create new webhook'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('addWebhookModal')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form ref="addWebhookModal" class="mt-2">
            <v-row>
              <v-col>
                <v-autocomplete
                  :label="
                    $i18n.locale === 'fr'
                      ? 'Action de l’évènement'
                      : 'Action of the event'
                  "
                  v-model="webhookToAdd.action"
                  :items="[
                    { text: '*', value: '*' },
                    { text: 'Ajouter', value: 'added' },
                    { text: 'Modifier', value: 'updated' },
                    { text: 'Supprimer', value: 'deleted' }
                  ]"
                  :placeholder="$t('search')"
                  :clearable="true"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  :persistent-placeholder="true"
                  item-text="text"
                  dense
                  item-value="value"
                  :no-data-text="$t('noDataOption')"
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  required
                  validate-on-blur
                  :rules="[
                    v =>
                      !!v ||
                      ($i18n.locale === 'fr'
                        ? 'Action de l’évènement'
                        : 'Action of the event') +
                        ' ' +
                        $t('msgOblg')
                  ]"
                >
                </v-autocomplete
              ></v-col>
              <v-col>
                <v-autocomplete
                  :label="
                    $i18n.locale === 'fr'
                      ? 'Objet de l’évènement'
                      : 'Object of the event'
                  "
                  v-model="webhookToAdd.object"
                  :items="[
                    { text: '*', value: '*' },
                    { text: 'Projets', value: 'projets' },
                    { text: 'Contacts', value: 'contacts' }
                  ]"
                  :placeholder="$t('search')"
                  :clearable="true"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  :persistent-placeholder="true"
                  item-text="text"
                  dense
                  item-value="value"
                  :no-data-text="$t('noDataOption')"
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  required
                  validate-on-blur
                  :rules="[
                    v =>
                      !!v ||
                      ($i18n.locale === 'fr'
                        ? 'Objet de l’évènement'
                        : 'Object of the event') +
                        ' ' +
                        $t('msgOblg')
                  ]"
                >
                </v-autocomplete
              ></v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  :label="
                    $i18n.locale === 'fr'
                      ? 'URL du point de destination'
                      : 'Destination point URL'
                  "
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="webhookToAdd.url"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[
                    v =>
                      !!v ||
                      ($i18n.locale === 'fr'
                        ? 'URL du point de destination'
                        : 'Destination point URL') +
                        ' ' +
                        $t('msgOblg')
                  ]"
                >
                </v-text-field
              ></v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getWebhookLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getWebhookError" class="error-msg">
              <ul v-if="Array.isArray(getWebhookError)">
                <li v-for="(e, index) in getWebhookError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getWebhookError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="ajouterWebhook"
            :loading="loading"
            small
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('addWebhookModal')" small>{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- delete webhook -->
    <v-dialog
      v-model="ModalDeleteWebhook"
      max-width="500"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale === 'fr' ? 'Supprimer un webhook' : 'Delete webhook'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('ModalDeleteWebhook')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <p>
            {{
              $t('msgdeleted', {
                msg: $i18n.locale === 'fr' ? 'le webhook' : 'the webhook'
              })
            }}
            <strong class="name_delete" v-if="webhookToDelete">
              {{ webhookToDelete.action | typeFormat }}.{{
                webhookToDelete.object
              }}
            </strong>
            ?
          </p>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getWebhookLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getWebhookError" class="error-msg">
              <ul v-if="Array.isArray(getWebhookError)">
                <li v-for="(e, index) in getWebhookError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getWebhookError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="supprimerWebhook"
            :loading="getWebhookLoading"
            small
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('ModalDeleteWebhook')" small>{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
export default {
  name: 'Webhook',
  data() {
    return {
      webhookToAdd: {
        action: null,
        object: null,
        url: null
      },
      webhookToDelete: null,
      addWebhookModal: false,
      ModalDeleteWebhook: false,
      initLoading: true,
      loading: false
    }
  },
  methods: {
    ...mapActions([
      'addWebhook',
      'deleteWebhook',
      'fetchListWebhook',
      'resetErrorWebhook'
    ]),
    handleClickBtnAddWebhook() {
      this.addWebhookModal = true
    },
    closeDialog(ref) {
      this[ref] = false
      if (ref == 'addWebhookModal') {
        this.$refs.addWebhookModal.resetValidation()
      }
      this.resetModal()
      this.resetErrorWebhook()
    },
    resetModal() {
      this.webhookToAdd = {
        action: null,
        object: null,
        url: null
      }
      this.webhookToDelete = null
    },
    clickdeleteWebhook(webhook) {
      this.webhookToDelete = webhook
      this.ModalDeleteWebhook = true
    },
    async ajouterWebhook() {
      if (this.$refs.addWebhookModal.validate()) {
        this.loading = true
        const response = await this.addWebhook(this.webhookToAdd)
        if (response) {
          this.closeDialog('addWebhookModal')
        }
        this.loading = false
      }
    },
    async supprimerWebhook() {
      const response = await this.deleteWebhook(this.webhookToDelete)
      if (response) {
        this.closeDialog('ModalDeleteWebhook')
      }
    }
  },
  computed: {
    ...mapGetters(['getWebhooks', 'getWebhookLoading', 'getWebhookError'])
  },
  filters: {
    typeFormat: value => {
      switch (value) {
        case 'added':
          return 'Ajouter'
        case 'updated':
          return 'Modifier'
        case 'deleted':
          return 'Supprimer'
        case '*':
          return '*'
        default:
          return ''
      }
    },
    date: function(value, format = 'DD/MM/YYYY') {
      if (!value) return ''
      return moment(value).format(format)
    },
    time: function(value, format = 'HH:mm:ss') {
      if (!value) return ''
      return moment(value).format(format)
    }
  },
  async mounted() {
    this.initLoading = true
    await this.fetchListWebhook()
    this.initLoading = false
  }
}
</script>
<style lang="scss" scoped>
.page-content-webhook {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .webhook {
    .webhook-table {
      //   border: 1px solid #dee2e6;
      tbody {
        tr {
          td {
            font-size: 12px;
          }
        }
      }
      .header-tr {
        th {
          //   border: 1px solid #dee2e6;
          font-size: 12px;
          color: black;
        }
      }
      .sub-header-tr {
        font-size: 12px;
        td {
          //   border: 1px solid #dee2e6;
          vertical-align: middle;
          .status-success,
          .status-error {
            font-weight: 600;
            &.status-success {
              color: #4caf50;
            }
            &.status-error {
              color: #ff5722;
            }
          }
          .trash {
            text-align: center;
            cursor: pointer;
            .icon-trash {
              height: 2em;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.page-content-webhook {
  .webhook {
    .v-data-table__wrapper {
      max-height: calc(100vh - 206px) !important;
      height: calc(100vh - 206px) !important;
      margin-bottom: 0;
      overflow-y: auto;
      overflow-x: auto;
      padding: 0px 0px;
      cursor: pointer;
    }
    .v-data-table__wrapper::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }
    .v-data-table__wrapper::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #f0eef8;
      border-radius: 4px;
      background-color: rgba(238, 238, 238, 1) !important;
    }
    .v-data-table__wrapper::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(189, 189, 189, 1);
    }
  }
}
</style>
